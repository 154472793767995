<template>
  <el-dialog v-model="dialogVisible" title="绑定邮箱" :close-on-click-modal="false" width="560px">
    <div class="dp-f mt-20 mb-30">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
        class="demo-ruleForm w100" label-position="left">
        <el-form-item label="邮箱" prop='email'>
          <el-input v-model="ruleForm.email" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入邮箱" />
        </el-form-item>
        <el-form-item label="请输入验证码" prop='code'>
          <div class="w100 dp-f">
            <el-input class="w100" v-model="ruleForm.code" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入验证码" maxlength="6" />
            <verificationCode class="ml-12" ref="verificationCodeRef" CSStype="2" @Emit="send">
            </verificationCode>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f jc-c">
        <oabutton class="m-0-at" width='462' height='52' style="border-radius: 4px;font-size: 18px;" title="确认" CSStype=2
          @buttonclick="dialogVisible = false">
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
//    import { validPhone, validEmail, validPassword } from "@/utils/server/validate.js";
import { validEmail } from "@/utils/server/validate.js";
import { httpToken } from "@/utils/request";
import qs from "qs";
import verificationCode from "@/components/button/verificationCode.vue"//验证码组件
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const verificationCodeRef = ref()//验证码组件ref
const state = reactive({
})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  email: '',//邮箱
  code: '',//验证码
})
// 发送验证码
const send = (() => {
  if (ruleForm.email == '') {
    handleMessage('请输入邮箱')
    return
  }
  if (!validEmail(ruleForm.email)) {
    handleMessage('邮箱格式错误')
    return
  }
  httpToken({
    method: "post",
    url: '/admin/app/sms',
    data: {
      'phone': JSON.parse(window.sessionStorage.user).user_info.phone,
      'exist': true,
      'appid': '1',
      'type': '11',
    }
  }).then(res => {
    unref(verificationCodeRef).send()
  })
})
// 校验邮箱格式
const validEmailData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入邮箱'))
  } else {
    if (validEmail(value)) {
      callback()
    } else
      callback(new Error('邮箱格式错误'))
  }
}
const rules = reactive({//from 表单校验
  email: [
    { validator: validEmailData, trigger: 'blur' }
  ],
  code: [
    { required: true, message: '请输入验证码' },
    { min: 6, max: 6, message: '验证码的长度为6', trigger: 'blur' }
  ],
})
// 表单重置
let fromDataReset = (() => {
  objdata_ret(ruleForm)
})
let show = (() => {
  fromDataReset()
  dialogloading.value = false
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      dataGeneration({
        ...ruleForm,
        type: 0
      })

    } else {
      dialogloading.value = false
    }
  })
}
// 数据生成
let dataGeneration = ((json, url = '/admin/addressbook/add') => {
  httpToken({
    method: "post",
    url: url,
    data: json,
  }).then((res) => {
    dialogloading.value = false
    dialogVisible.value = false
    emit('Emit', state.key, res.data)
  }).catch(() => {
    dialogloading.value = false
  })
})
defineExpose({
  show
});
</script>
<style lang="scss" scoped>
@import "@/styles/general/element/settingFrom.scss"; //element 样式重置

::v-deep .el-form-item__label {
  display: none;
}
</style>