<template>
  <item title="真实姓名" isbg="true" class="mt-12 pr-50">
    <template v-slot:img>
      <span class="icon iconfont icon-zhanghao dp-fc fs-52"></span>
    </template>
    <template v-slot:lable>
      <div class="lable">
        {{ DesChname }}
      </div>
    </template>
    <template v-slot:more>
      <div class="w-74 h-50 dp-fc ml-at userIconbox">
        <div class="templatebox w-50 h-50 br-50" @click="unref(EditAvatarRef).show()" >编辑</div>
        <img :src="userInfo.avatar" class="w-50 h-50 userIcon cu-p br-50" v-if="userInfo.avatar">
        <img src="@/assets/img/general/user.png" class="w-50 h-50 userIcon cu-p br-50" v-else />
      </div>
    </template>
  </item>
  <item title="账号密码" class="mt-6 pr-50">
    <template v-slot:img>
      <span class="icon iconfont icon-mima dp-fc s-52"></span>
    </template>
    <template v-slot:lable>
      <div class="lable" v-if="userInfo.password">
        已设置
      </div>
      <div class="lable not" v-else>
        未设置
      </div>
    </template>
    <template v-slot:more>
      <oabutton class="ml-at" width='74' height='32' :title="userInfo.password ? '更改' : '去设置'" CSStype=1
        style="color: #999999;" @buttonclick="state.smstype = 4, unref(authenticationRef).check('password')"/>
    </template>
  </item>
  <item title="手机号" class="mt-6 pr-50">
    <template v-slot:img>
      <span class="icon iconfont icon-shoujihao dp-fc fs-52"></span>
    </template>
    <template v-slot:lable>
      <div class="lable" v-if="userInfo.phone">
        <span>已绑定</span>
        {{ DesPhone }}
      </div>
      <div class="lable not" v-else>
        未设置
      </div>
    </template>
    <template v-slot:more>
      <oabutton class="ml-at" width='74' height='32' :title="userInfo.phone ? '更改' : '去设置'" CSStype=1
        style="color: #999999;" @buttonclick="state.smstype = 10, unref(authenticationRef).check('phone')"/>
    </template>
  </item>
  <!-- <item title="邮箱(未对接)" class="mt-6 pr-50">
    <template v-slot:img>
      <img src="@/assets/img/user/email.png" class="w100">
    </template>
    <template v-slot:lable>
      <div class="lable not">
        未绑定
      </div>
    </template>
    <template v-slot:more>
      <oabutton class="ml-at" width='74' height='32' title="去绑定(未对接)" CSStype=1 style="color: #999999;" @buttonclick="unref(bindingemilRef).show()"></oabutton>
    </template>
  </item> -->
  <item title="注销账号" class="mt-6 pr-50">
    <template v-slot:img>
      <span class="icon iconfont icon-zhuxiao dp-fc fs-52"></span>
    </template>
    <template v-slot:lable>
      <div class="lable">
        注销将删除或匿名化处理该账号下的数据，一旦注销将无法恢复，请在申请注销前做好备份
      </div>
    </template>
    <template v-slot:more>
      <oabutton class="ml-at" width='74' height='32' title="申请注销" CSStype=1 style="color: #999999;"
        @buttonclick="accountClick"/>
    </template>
  </item>
  <authentication ref="authenticationRef" @Emit="authenticationEmit" v-model:smstype="state.smstype"></authentication>
  <password ref="passwordRef"></password>
  <bindingphone ref="bindingphoneRef"></bindingphone>
  <bindingemil ref="bindingemilRef"></bindingemil>
  <EditAvatar ref="EditAvatarRef"></EditAvatar>
</template>

<script setup>
import { chnameDesensitization, phoneDesensitization } from "@/hooks/desensitization.js"
import { ref, unref, reactive, computed } from "vue"
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import { httpToken } from "@/utils/request";
import { accountCancellation } from "@/api/user/index"
import qs from "qs";
import item from "../setting/item.vue"
import authentication from "../setting/authentication.vue"//身份验证弹框
import bindingphone from "../setting/bindingphone.vue"//绑定手机号弹框
import bindingemil from "../setting/bindingemil.vue"//绑定邮箱弹框
import password from "../setting/password.vue"//修改密码弹框
import EditAvatar from '../setting/neweditAvatar.vue'//修改头像弹框
const authenticationRef = ref()//身份验证弹框ref
const passwordRef = ref()//修改密码弹框ref
const bindingemilRef = ref()//绑定邮箱弹框ref
const bindingphoneRef = ref()//绑定手机号弹框ref
const EditAvatarRef = ref()//修改头像弹框ref
const state = reactive({
  smstype: 4,//type 1 登录 2 注册 3 重置密码 4 修改密码 5 实名认证 6 设置签署密码 7 签署密码校验 8 用户注销 9 新任务通知 10修改手机号
})
const userInfo = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info
})
const DesChname = chnameDesensitization(JSON.parse(window.sessionStorage.user).user_info.chnname)
const DesPhone = phoneDesensitization(JSON.parse(window.sessionStorage.user).user_info.phone)
// 注销账号
const accountClick = () => {
  handleCofirm('注销将删除或匿名化处理该账号下的数据，一旦注销将无法恢复，请在申请注销前做好备份？').then(() => {
    accountCancellation()
  }).catch(() => {
    handleMessage('已取消', 'info')
  })
}
// 身份验证反回信息
const authenticationEmit = ((key) => {
  if (key == 'password') {
    unref(passwordRef).show()
  } else if (key == 'phone') {
    unref(bindingphoneRef).show()
  }
})
</script>
<style lang="scss" scoped>
.lable {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;

  span {
    color: rgba(24, 144, 255, 1);
  }
}

.not {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F5222D;
}

.userIconbox {
  cursor: pointer;
  position: relative;

  .userIcon {}

  .templatebox {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    display: none;
  }
}

.userIconbox:hover {
  .templatebox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>