<template>
    <div class="setting w100 h-80">
        <div class="ocbg" v-if="props.isbg"></div>
        <div class="w100 h100 dp-f ai-c pl-30 allbox">
            <div class="icon w-50 h-50 dp-fc">
                <slot name="img"></slot>
            </div>
            <div class="ml-14">
                <div class="title mb-8">{{ props.title }}</div>
                <slot name="lable"></slot>
            </div>
            <slot name="more"></slot>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from "vue"
const props = defineProps({
    isbg: {
        //当前步骤下标
        type: Boolean,
        default: () => {
            return false
        }
    },
    title:{
        type: String,
        default: () => {
            return '标题'
        }
    }
});
const state = reactive({

})
</script>
<style lang="scss" scoped>
@import '@/styles/color/value.scss';

.setting {
    position: relative;
    .ocbg {
        // background: $active-theme;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0.05;
        z-index: 1;
    }
    .allbox{
        position: relative;
        z-index: 2;
    }
    .icon {
        background: $active-alpha;
    }
    .title{
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }
}
</style>