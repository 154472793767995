<template>
    <el-dialog v-model="dialogVisible" title="设置密码" :close-on-click-modal="false" width="560px">
        <div class="dp-f mt-20 mb-30">
            <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
                class="demo-ruleForm w100" label-position="left">
                <el-form-item label="密码" prop='password'>
                    <el-input v-model="ruleForm.password" :maxlength="config.inputMaxlength" show-word-limit
                        placeholder="请输入8～16位密码" show-password/>
                </el-form-item>
                <el-form-item label="请重新输入密码" prop='again'>
                    <el-input v-model="ruleForm.again" :maxlength="config.inputMaxlength" show-word-limit
                        placeholder="请重新输入密码" show-password/>
                </el-form-item>
            </el-form>
        </div>
        <template #footer>
            <span class="dialog-footer dp-f jc-c">
                <oabutton v-model:loading="dialogloading" class="m-0-at" width='462' height='52' style="border-radius: 4px;font-size: 18px;" title="确认"
                    CSStype=2 @buttonclick="submitForm">
                </oabutton>
            </span>
        </template>
    </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { objdata_ret, objdata_set } from "@/utils/server/format";
//    import { validPhone, validEmail, validPassword } from "@/utils/server/validate.js";
import { validPassword } from "@/utils/server/validate.js";
import { httpToken } from "@/utils/request";
import {loginOut} from "@/api/login"
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
    password: '',//密码
    again: '',//再次输入密码
})
// 校验密码格式
const validPasswordData = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入密码'))
    } else {
        if (validPassword(value)) {
            callback()
        } else
            callback(new Error('密码格式错误(8-16位数字,字母和特殊字符任意两种组合密码)'))
    }
}
const validAgainData = (rule, value, callback) => {
    if(ruleForm.password==ruleForm.again){
        callback()
    }else{
        callback(new Error('二次输入密码不相同'))
    }
}
const rules = reactive({//from 表单校验
    password: [
        { validator: validPasswordData, trigger: 'blur' }
    ],
    again:[
        { validator: validAgainData, trigger: 'blur' }
    ]
})
// 表单重置
let fromDataReset = (() => {
    objdata_ret(ruleForm)
})
let show = (() => {
    fromDataReset()
    dialogloading.value = false
    dialogVisible.value = true
    nextTick(() => {
        unref(ruleFormRef).clearValidate()//清除表单验证结果
    })
})
// 表单提交
const submitForm = async () => {
    dialogloading.value = true
    const form = unref(ruleFormRef)
    if (!form) {
        dialogloading.value = false
        return
    }
    await form.validate((valid) => {
        if (valid) {
            dataGeneration({
                password:ruleForm.password,
            })
        } else {
            dialogloading.value = false
        }
    })
}
// 数据生成
let dataGeneration = ((json, url = '/admin/sys-user/edit') => {
    httpToken({
        method: "post",
        url: url,
        data: json,
    }).then((res) => {
        loginOut()
        // dialogloading.value = false
        // dialogVisible.value = false
        // emit('Emit', state.key, res.data)
    }).catch(() => {
        dialogloading.value = false
    })
})
defineExpose({
    show
});
</script>
<style lang="scss" scoped>
@import "@/styles/general/element/settingFrom.scss"; //element 样式重置

::v-deep .el-form-item__label {
    display: none;
}
</style>